import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import logo from "./assets/INHOUSE.gif"

import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";



const ConnectButton = styled(WalletDialogButton)``;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

const MintButton = styled(Button)``; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
    })();
  };

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);

  return (
    <main>












<body >


<div className="ag-fireflies_box">
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
  <div className="ag-fireflies_item">
    <div className="ag-fireflies_inner" />
  </div>
</div>

{/* 
<img
                src={logo}
                className=""
                id = "bg"
                
                alt="..."
              /> */}



<section className="container-fluid " id="container">
  <div className="left-half" id=".left-half">
    <article>
      <h1>IN-HOUSE SALE BABY</h1>
      <img
                src={logo}
                className=""
                id = "seedz"
                
                alt="..."
              />


      <div id="bubly">
  <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
    <defs>
      <filter id="gooey">
        {/* in="sourceGraphic" */}
        <feGaussianBlur in="SourceGraphic" stdDeviation={5} result="blur" />
        <feColorMatrix in="blur" type="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="highContrastGraphic" />
        <feComposite in="SourceGraphic" in2="highContrastGraphic" operator="atop" />
      </filter>
    </defs>
  </svg>

  <MintContainer style={{paddingBottom:"5%", paddingTop:"10%"}} >
        {!wallet ? (
          <ConnectButton className="cta " id= "gooey-button">Connect Wallet

<span className="bubbles">
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
    </span>
          
          </ConnectButton>
          
        ) : (
          <MintButton className="cta " id="gooey-button" 
            disabled={isSoldOut || isMinting || !isActive}
            onClick={onMint}
            variant="contained"
          >
            <span className="bubbles-m">
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
      <span className="bubble" />
   
      
   
    </span>
    
            {isSoldOut ? (
              "SOLD OUT"
            ) : isActive ? (
              isMinting ? (
                <CircularProgress />
              ) : (
                "MINT"
              )
            ) : (
              <Countdown
                date={startDate}
                onMount={({ completed }) => completed && setIsActive(true)}
                onComplete={() => setIsActive(true)}
                renderer={renderCounter}
              />
            )}
          </MintButton>
          
        )}


      </MintContainer>


</div>


      {wallet && (
            <p style = {{ textAlign:"center" , color:"white"}}>Wallet <span style = {{color:"#3B933B" , textAlign:"center"}}>{shortenAddress(wallet.publicKey.toBase58() || "")}</span></p>
          )}

   {/* {wallet && <p style = {{ textAlign:"center"}}>Balance <span style = {{color:"red"}}>{(balance || 0).toLocaleString()}</span> SOL</p>}   */}

 {wallet && <p style = {{ textAlign:"center" , color:"white"}}>Remaining <span style = {{color:"#3B933B"}}>{itemsRemaining}/150</span></p>}
      
    </article>
  </div>
  <div className="right-half" id="right-half">
    <article>
      {/* <h1>Right Half</h1>
      <p>If your knees aren't green by the end of the day, you ought to seriously re-examine your life.</p> */}
    </article>
  </div>
</section>






{/* <div className="container-fluid"  id="krypto">

  <div className="aside-left" id="aside-left">
  <h1 style ={{color:"white", textAlign:"left", marginLeft:"2%",marginBottom:"5%", position: "relative", marginTop:"-3%" }}>SEEDZ PRESALE</h1>
  <img
                src={logo}
                className=""
                id = "seedz"
                
                alt="..."
              />
  <MintContainer style={{paddingBottom:"5%"}} >
        {!wallet ? (
          <ConnectButton className="cta" id= "connect-wallet" >Connect Wallet</ConnectButton>
        ) : (
          <MintButton className="cta" id="mint-btn" style = {{ color:"white"}}
            disabled={isSoldOut || isMinting || !isActive}
            onClick={onMint}
            variant="contained"
          >
            {isSoldOut ? (
              "SOLD OUT"
            ) : isActive ? (
              isMinting ? (
                <CircularProgress />
              ) : (
                "MINT"
              )
            ) : (
              <Countdown
                date={startDate}
                onMount={({ completed }) => completed && setIsActive(true)}
                onComplete={() => setIsActive(true)}
                renderer={renderCounter}
              />
            )}
          </MintButton>
          
        )}


      </MintContainer>

      

      {wallet && (
            <p style = {{ textAlign:"center" , color:"white"}}>Wallet <span style = {{color:"#3B933B" , textAlign:"center"}}>{shortenAddress(wallet.publicKey.toBase58() || "")}</span></p>
          )}

   {wallet && <p style = {{ textAlign:"center"}}>Balance <span style = {{color:"red"}}>{(balance || 0).toLocaleString()}</span> SOL</p>}  

 {wallet && <p style = {{ textAlign:"center" , color:"white"}}>Remaining <span style = {{color:"#3B933B"}}>{itemsRemaining}/16</span></p>} 
    

  </div>
  <div className="aside-right"  id="aside-right">

  </div>


</div>
</body>

<footer className="" style = {{background:"black", color: "white" , padding:"20px", textAlign:"center"}}>
   <h3>Pre Sale</h3>
</footer> */}

</body>


{/* {wallet && (
            <p>Wallet {shortenAddress(wallet.publicKey.toBase58() || "")}</p>
          )}
    
          {wallet && <p>Balance: {(balance || 0).toLocaleString()} SOL</p>} */}
     

      {/* {wallet && <p>Total Available: {itemsAvailable}</p>} */}

      {/* {wallet && <p>Redeemed: {itemsRedeemed}</p>}

      {wallet && <p>Remaining: {itemsRemaining}</p>} */}

      

      <Snackbar
        open={alertState.open}
        autoHideDuration={6000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};

export default Home;







<header className="container-fluid d-flex">
  {/* <a className="logo" href="/"><img src="images/logo.svg" alt="logo" /></a> */}
<div style = {{ width:"350px", fontSize:"10vw"}} >
<h2 className=""  id ="heading" style = {{textAlign:"center" ,fontSize:"20px"}}>Elite Tiger Club</h2>

</div>

  
 
  {/* <a className="cta" href="#">Contact</a> */}

<div className="d-flex"style = {{ width:"350px", }}>




</div >
  
      



<div style = {{ width:"350px",}} >
<nav>
    <ul className="links"  style = {{ margin:"0.5rem 0px"}}>
      

      <li><a href = "#/"><i className ="fab fa-discord fa-2x"></i></a></li>
      <li><a href = "#/"><i className ="fab fa-twitter fa-2x "></i></a></li>
    </ul>
  </nav>
</div>


  
</header>